import request from '@/utils/request'
// 质押lp初始化页面
export const queryLpInfo = (params) => {
  return request('GET', '/app/contract/pledge_page', params, true, true)
}

// 质押时间下拉框
export const queryStakeTime = (params) => {
  return request('GET', '/app/pledge_time/list', params, true, false, 1)
}

// 预计获得原力值
export const queryEstimateForceValue = (params) => {
  return request('GET', '/app/force_value/estimate_force_value', params, true, true)
}

// 质押lp初始化页面
export const queryUnStakingInfo = (params) => {
  return request('GET', '/app/contract/redeem_page', params, true, true)
}

// 更新LP质押状态
export const queryInquiryStake = (params) => {
  return request('GET', '/app/contract/inquiry_time', params, true, true)
}

// 质押LP
export const queryDeposit = (params) => {
  return request('POST', '/app/contract/lp_pledge', params, true, true)
}

// 质押列表
export const queryStakeList = (params) => {
  return request('GET', '/app/contract/redeem_page', params, true, true)
}

// 赎回LP
export const queryRedeem = (params) => {
  return request('GET', '/app/contract/lp_redeem', params, true, true)
}

// 获取txid
export const getTxid = (params) => {
  return request('GET', '/app/contract/get_TxId', params, true, true)
}
