<template>
  <VanPopup
    v-model="removeShow"
    style="width: 93%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="lp-wrap">
      <div class="title-wrap">
        <div class="title">
          <div class="title">{{ staking.removeTitle }}</div>
          <div class="close-icon" @click="closePop">✕</div>
        </div>
        <div class="to-pancake" @click="handleToPancake">
          {{ staking.pancake }} <i class="iconfont icon icon-view"></i>
        </div>
      </div>
      <div class="lp-box">
        <div class="lp-balance">
          <div>{{ staking.enterNotice }}</div>
          <div>{{ staking.balance }}: {{ lpbalance }}</div>
        </div>
        <div class="input-box">
          <input
            class="input-border"
            :placeholder="staking.notice"
            v-model="lpAmount"
            oninput="value=value.match(/^\d+(?:\.\d{0,8})?/)"
          />
          <div class="max-btn" @click="handleToMax">{{ staking.max }}</div>
        </div>
        <!-- 输入lp的价值 -->
        <div class="meb-balance">
          {{ staking.nowValue }}: {{ lpTotalPrice }} USDT
        </div>
      </div>
      <div class="lp-btn-groups">
        <div class="approve-btn" v-if="btnLoading">
          <VanLoading color="#fff" />
        </div>
        <template v-else>
          <div class="approve-btn" v-if="!allanceFlag" @click="approveLp">
            <span v-if="!authLoading"> {{ stakeTips.Apporve }}</span>
            <van-loading v-else color="#fff" />
          </div>
          <div class="approve-btn" v-else @click="handleRemove">
            <span v-if="!removeLoading"> {{ staking.remove }}</span>
            <van-loading v-else color="#fff" />
          </div>
        </template>
      </div>
    </div>
  </VanPopup>
</template>
<script>
import config from '@/config/app.config'
import stakeLock from '@/utils/stakeLock'
import pancakeObj from '@/utils/pancakeRouter'
const contractsInfo = config.contractsInfo
export default {
  name: 'Remove',
  props: {
    // lp的地址
    lpObj: {
      type: Object
    },
    lpbalance: {
      type: Number
    },
    allowanceLP: {
      type: Number
    }
  },
  data () {
    return {
      removeShow: true,
      tokenUsdt: contractsInfo.tokenUSDTContract,
      tokenMeb: contractsInfo.tokenGEPContract,
      lpAmount: null, // lp输入值
      authLoading: false,
      removeLoading: false,
      btnLoading: true,
      lpBalance: 0, // lp剩
      lpPrice: 0, // lp单价
      lpAllowance: 0, // lp授权额度
      allanceFlag: false // 授权标识 true 已经授权 false未授权
    }
  },
  computed: {
    stakeTips () {
      return this.$t('common.stakeTips')
    },
    staking () {
      return this.$t('staking.removeLiquidty')
    },
    // lp总价
    lpTotalPrice () {
      return this.$gbUtils.formatTwoBalance(this.lpPrice * this.lpAmount)
    }
  },
  methods: {
    init () {
      this.getBalance()
      // 获取授权额度
      this.getAllowance()
      this.getLpPrice()
    },
    // 跳转到pancake
    handleToPancake () {
      window.open(`${config.pancakeUrl + config.pancakePool}`)
    },
    // 弹窗显示及隐藏
    closePop () {
      this.$emit('close')
    },
    // 获取剩
    async getBalance () {
      const lpResp = await this.$web3.balanceOf(this.lpObj)
      if (lpResp.success) {
        this.lpBalance = this.$gbUtils.formatTwoBalance(lpResp.result) || 0
      }
    },
    async getAllowance () {
      // 获取授权额度
      this.btnLoading = true
      const allanceResp1 = await pancakeObj.allowance(this.lpObj)
      this.btnLoading = false
      console.log('lpAllowance', allanceResp1.result)
      if (allanceResp1.success) {
        this.lpAllowance = allanceResp1.result
        if (allanceResp1.result > 0) this.allanceFlag = true
        else this.allanceFlag = false
      }
    },
    // lp最大
    handleToMax () {
      this.lpAmount = this.lpBalance
    },
    // 授权lp
    async approveLp () {
      this.authLoading = true
      this.$toast.loading({ duration: 60000 })
      const resp = await pancakeObj.approveContract(
        this.lpObj,
        100000000000000000000
      ) // 授权lptoken
      this.$toast.clear()
      if (!resp.success) return this.$toast(resp.message.message)
      this.getAllowance()
      this.authLoading = false
    },
    // 移除流动性
    async handleRemove () {
      if (this.removeLoading) return
      if (!this.lpAmount) {
        return
      }
      if (this.lpAmount > parseInt(this.lpBalance) + 1) {
        return this.$toast(this.stakeTips.insufficient)
      }
      this.removeLoading = true
      const resp = await pancakeObj.removeLiquidity(this.lpAmount)
      this.removeLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.$toast('success')
      this.init()
    },
    // 获取lp价值
    async getLpPrice () {
      const lpPriceResp = await stakeLock.getLpPrice(this.poolId || 0)
      if (lpPriceResp.success) {
        this.lpPrice = this.$gbUtils.formatBalance(lpPriceResp.result)
        console.log('lp value:')
      }
    }
  },
  watch: {
    lpAmount (val) {
      const inputVal = parseFloat(val)
      if (inputVal > 0 && inputVal > this.lpAllowance) {
        this.allanceFlag = false
      } else {
        this.allanceFlag = this.lpAllowance > 0
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.lp-wrap {
  padding: 20px;
  box-sizing: border-box;
  .title-wrap {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      color: #2a2b33;
    }
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #66eab9 !important;
      border-radius: 10px;
      text-align: center;
      line-height: 10px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
    .to-pancake {
      padding-top: 5px;
      font-size: 12px;
      color: #66eab9;
      cursor: pointer;
      .icon {
        font-size: 12px;
      }
    }
  }
  .lp-box {
    .lp-balance {
      font-size: 12px;
      font-weight: bold;
      color: #16172a;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:last-child {
        font-weight: normal;
      }
    }
    .input-box {
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 0 10px 0 20px;
      box-sizing: border-box;
      justify-content: space-between;
      background: #f0f0f0;
      input {
        background: #f0f0f0;
        border: none;
      }
      .max-btn {
        cursor: pointer;
        width: 66px;
        height: 30px;
        background: #66eab9;
        opacity: 1;
        border-radius: 10px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        font-weight: bold;
      }
    }
    .meb-amount {
      padding-top: 20px;
      color: #66eab9;
      font-size: 12px;
      font-weight: bold;
    }
    .meb-balance {
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .lp-btn-groups {
    text-align: center;
    margin-top: 20px;
    bottom: 20px;
    left: 50%;
    width: 100%;
    .approve-btn {
      height: 50px;
      background-color: #66eab9;
      line-height: 50px;
      border-radius: 10px;
      color: #000;
      font-weight: bold;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
