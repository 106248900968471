<template>
  <div class="staking-tab-list">
    <van-button
      class="staking-item staking-btn"
      :class="{ active: path === '/ecologicalRewardMusd' }"
      @click="handleToPage('/ecologicalRewardMusd')"
    >
     <div>{{ stake.ecologicalReward }}</div>
     <div>(MEB-MUSD-LP)</div>
    </van-button>
    <van-button
      class="staking-item staking-btn"
      :class="{ active: path === '/ecologicalReward' }"
      @click="handleToPage('/ecologicalReward')"
    >
     <div>{{ stake.ecologicalReward }}</div>
     <div>(MEB-USDT-LP)</div>
    </van-button>
    <div
      class="staking-item"
      :class="{ active: path === '/staking' }"
      @click="handleToPage('/staking')"
    >
      {{ stake.commonStakeText }}
    </div>
    <div
      class="staking-item"
      :class="{ active: path === '/singleStaking' }"
      @click="handleToPage('/singleStaking')"
    >
      {{ stake.singleStakeText }}
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
    }
  },
  computed: {
    path () {
      return this.$route.path || ''
    },
    stake () {
      return this.$t('staking')
    }
  },
  watch: {
  },
  methods: {
    handleToPage (path) {
      if (this.path === path) return
      this.$gbUtils.handleToPage(path)
    }

  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.staking-tab-list {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .staking-item {
    width: 50%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 8px;
    font-weight: bold;
    cursor: pointer;
    &.active {
      background: #66eab9;
    }
    &.active1 {
      background: #a85ffc;
    }
    &.staking-btn {
      width: 50%;
      height: 50px;
      line-height: inherit;
      font-size: 16px;
      color: #000;
      border: none;
    }
  }
}
</style>
