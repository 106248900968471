<template>
  <VanPopup
    v-model="addShow"
    style="width: 93%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="lp-wrap">
      <div class="title-wrap">
        <div class="title">
          <div class="title">{{ staking.getLp }}</div>
          <div class="close-icon" @click="closePop">✕</div>
        </div>
        <div class="to-pancake" @click="handleToPancake">
          {{ staking.pancake }} <i class="iconfont icon icon-view"></i>
        </div>
      </div>
      <div class="lp-box">
        <div class="lp-balance">
          <div>{{ staking.enterNotice }}</div>
          <div>{{ staking.balance }}: {{ usdtBalance }}</div>
        </div>
        <div class="input-box">
          <input
            class="input-border"
            :placeholder="staking.notice + needUsdt"
            v-model="usdtAmount"
            oninput="value=value.match(/^\d+(?:\.\d{0,8})?/)"
          />
          <div class="max-btn" @click="handleToMax">{{ staking.max }}</div>
        </div>
        <!-- 提示需要的meb值 -->
        <div class="meb-amount">{{ staking.needMeb }}{{ mebAmount }} MEB</div>
        <div class="meb-balance">
          {{ staking.balance }}: {{ mebBalance }} MEB
        </div>
      </div>
      <div class="lp-btn-groups">
        <div class="approve-btn" v-if="btnLoading">
          <van-loading color="#fff" />
        </div>
        <template v-else>
          <div
            class="approve-btn"
            v-if="authUSDT && authMEB"
            @click="handleSupply"
          >
            <span v-if="!getLPLoading">{{ staking.get }}</span>
            <van-loading v-else color="#fff" />
          </div>
          <div
            class="approve-btn"
            v-if="!authUSDT"
            @click="approveUSDTContract"
            ref="authUSDT"
          >
            <span v-if="!authUSDTLoading">{{ staking.authUSDT }}</span>
            <van-loading v-else color="#fff" />
          </div>
          <div
            class="approve-btn"
            ref="authMEB"
            @click="approveMEBContract"
            v-else-if="!authMEB"
          >
            <span v-if="!authMEBLoading">{{ staking.authMEB }}</span>
            <van-loading v-else color="#fff" />
          </div>
        </template>
      </div>
    </div>
  </VanPopup>
</template>
<script>
import config from '@/config/app.config'
import pancakeObj from '@/utils/pancakeRouter'
const contractsInfo = config.contractsInfo
export default {
  name: 'liquidity',
  data () {
    return {
      addShow: true,
      tokenUsdt: contractsInfo.tokenUSDTContract,
      tokenMeb: contractsInfo.tokenGEPContract,
      usdtAmount: null, // usdt输入值
      mebAmount: 0, // 需要的meb数量
      authUSDTLoading: false,
      authMEBLoading: false,
      getLPLoading: false, // 获取loading
      btnLoading: true,
      authUSDT: false,
      authMEB: false,
      usdtBalance: 0, // usdt余额
      mebBalance: 0, // meb余额
      usdtAllowance: 0, // usdt授权额度
      mebAllowance: 0 // meb授权额度
    }
  },
  props: {
    userStakeValue: {
      default () {
        return 0
      }
    }
  },
  computed: {
    stakeTips () {
      return this.$t('common.stakeTips')
    },
    staking () {
      return this.$t('staking.addLiquidty')
    },
    needUsdt () {
      return this.userStakeValue > 0 ? 50.1 : 501
    }
  },
  methods: {
    init () {
      this.getBalance()
      // 获取授权额度
      this.getAllowance()
      // 获取默认需要的meb
      this.getMebAmount()
    },
    // 跳转到pancake
    handleToPancake () {
      window.open(`${config.pancakeUrl + config.pancakeAdd}`)
    },
    closePop () {
      this.$emit('close')
    },
    // 监听USDT输入
    handleUSDTInput () {
      parseInt(this.usdtAmount) < parseInt(this.usdtAllowance)
        ? (this.authUSDT = true)
        : (this.authUSDT = false)
      console.log('authMEB', this.authMEB)
    },
    // 授权USDT
    async approveUSDTContract () {
      this.authUSDTLoading = true
      this.$refs.authUSDT.style.pointerEvents = 'none'
      const USDTObj = {
        abi: this.tokenUsdt.abi,
        address: this.tokenUsdt.address
      }
      const resp = await pancakeObj.approveContract(USDTObj, 1000000000000000) // 授权USDT
      this.$refs.authUSDT.style.pointerEvents = 'auto'
      this.authUSDTLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      // console.log('签名回调', resp)
      this.$refs.authUSDT.style.pointerEvents = 'auto'
      this.authUSDTLoading = false
      this.authUSDT = true
      this.getAllowance()
    },
    // 授权MEB
    async approveMEBContract () {
      this.authMEBLoading = true
      this.$refs.authMEB.style.pointerEvents = 'none'
      const MEBObj = {
        abi: this.tokenMeb.abi,
        address: this.tokenMeb.address
      }
      const resp = await pancakeObj.approveContract(MEBObj, 1000000000000000) // 授权MEB
      if (this.$refs.authMEB) { this.$refs.authMEB.style.pointerEvents = 'auto' }
      this.authMEBLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      // console.log('签名回调', resp)
      this.authMEB = true
      this.getAllowance()
    },
    // 获取余额
    async getBalance () {
      const usdtResp = await this.$web3.balanceOf(this.tokenUsdt)
      if (usdtResp.success) {
        this.usdtBalance = this.$gbUtils.formatTwoBalance(usdtResp.result) || 0
      }
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatTwoBalance(mebResp.result) || 0
      }
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.btnLoading = true
      const allanceResp1 = await pancakeObj.allowance(this.tokenUsdt)
      console.log('usdtAllowance', allanceResp1.result)
      if (allanceResp1.success) {
        this.usdtAllowance = allanceResp1.result
        this.authUSDT = this.usdtAllowance > 0
      }
      const allanceResp2 = await pancakeObj.allowance(this.tokenMeb)
      console.log('mebAllowance', allanceResp2.result)
      if (allanceResp2.success) {
        this.mebAllowance = allanceResp2.result
        this.authMEB = allanceResp2.result > 0
      }
      console.log(this.authUSDT)
      console.log(this.authMEB)
      this.btnLoading = false
      // console.log(``, );
    },
    // usdt最大
    handleToMax () {
      this.usdtAmount = this.usdtBalance
    },
    // 获取所需meb的值
    async getMebAmount () {
      parseInt(this.usdtAmount) < parseInt(this.usdtAllowance)
        ? (this.authUSDT = true)
        : (this.authUSDT = false)
      console.log('authMEB', this.authMEB)
      var usdtAmount
      if (!this.usdtAmount) usdtAmount = this.needUsdt
      else usdtAmount = this.usdtAmount
      const path = [this.tokenUsdt.address, this.tokenMeb.address]
      const resp = await pancakeObj.getAmountsOut(usdtAmount, path)
      if (!resp.success) return
      this.mebAmount = this.$gbUtils.formatBalance(resp.result)
    },
    // 添加流动性
    async handleSupply () {
      // const lpAmount = Math.sqrt(this.usdtAmount * this.mebAmount)
      if (this.getLPLoading) return
      if (!this.usdtAmount) {
        return
      }
      if (this.usdtAmount > parseInt(this.usdtBalance) + 1) {
        return this.$toast('USDT' + this.stakeTips.insufficient)
      }
      if (this.mebAmount > parseInt(this.mebBalance) + 1) {
        return this.$toast('MEB' + this.stakeTips.insufficient)
      }
      this.getLPLoading = true
      const resp = await pancakeObj.addLiquidity(
        this.usdtAmount,
        this.mebAmount,
        2
      )
      this.$emit('changeLoading', false)
      this.getLPLoading = false
      if (!resp.success) return this.$toast(resp.message.message)
      this.init()
      this.$parent.init()
    }
  },
  watch: {
    usdtAmount (val) {
      if (val) {
        this.getMebAmount()
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.lp-wrap {
  padding: 20px;
  box-sizing: border-box;
  .title-wrap {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: bold;
      color: #2a2b33;
    }
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: #66eab9 !important;
      border-radius: 10px;
      text-align: center;
      line-height: 10px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
    .to-pancake {
      padding-top: 5px;
      font-size: 12px;
      color: #66eab9;
      cursor: pointer;
      .icon {
        font-size: 12px;
      }
    }
  }
  .lp-box {
    .lp-balance {
      font-size: 12px;
      font-weight: bold;
      color: #16172a;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div:last-child {
        font-weight: normal;
      }
    }
    .input-box {
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 0 10px 0 20px;
      box-sizing: border-box;
      justify-content: space-between;
      background: #f0f0f0;
      input {
        background: #f0f0f0;
        border: none;
      }
      .max-btn {
        cursor: pointer;
        width: 66px;
        height: 30px;
        background: #66eab9;
        opacity: 1;
        border-radius: 10px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        font-weight: bold;
      }
    }
    .meb-amount {
      padding-top: 20px;
      color: #66eab9;
      font-size: 12px;
      font-weight: bold;
    }
    .meb-balance {
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .lp-btn-groups {
    text-align: center;
    margin-top: 20px;
    bottom: 20px;
    left: 50%;
    width: 100%;
    .approve-btn {
      height: 50px;
      background-color: #66eab9;
      line-height: 50px;
      border-radius: 10px;
      color: #000;
      font-weight: bold;
      margin: 0 auto;
      cursor: pointer;
    }
  }
}
</style>
