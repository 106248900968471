<template>
  <div>
    <div class="total-tvl" @click="handleToLock">
      <div class="tvl-title">
        <img src="@/static/img/staking/tvl.png" />
        <div class="tvl-title-name">{{ staking.TVL }}</div>
        <!-- <span
          class="iconfont icon-liulanqi2 arrow"
          @click.stop="$gbUtils.handleToPage('/browers')"
        ></span> -->
      </div>
      <div class="total-num" v-if="loadingFlag1 && loadingFlag2">
        {{ $gbUtils.formatNumber(lpTotalPrice) }}
      </div>
      <VanLoading color="#66EAB9" v-else />
    </div>
  </div>
</template>
<script>
import stakeLock from '@/utils/stakeLock'
import config from '@/config/app.config.js'
export default {
  name: 'TotalTvl',
  data () {
    return {
      loadingFlag1: false, // 获取lpTotalSupply标识
      loadingFlag2: false, // 获取lpPrice标识
      pid: 0, // 默认第一个质押池
      lpPrice: 0, // lp价值
      lpTotalSupply: 0, // 总质押lp
      lpTotalPrice: 0, // 总质押lp的价值,
      timer: null, // 定时器
      lpAddress: '0x9b22403637F18020B78696766d2Be7De2F1a67e2'
    }
  },
  computed: {
    staking () {
      return this.$t('staking')
    }
  },
  methods: {
    // 跳转到bsc锁仓合约
    handleToLock () {
      const url = config.bscBrowser + '/address/' + this.lpAddress
      window.open(url)
    },
    // 获取lp价值
    async getLpPrice () {
      const lpPriceResp = await stakeLock.getLpPrice(this.poolId || 0)
      if (lpPriceResp.success) {
        this.lpPrice = this.$gbUtils.formatBalance(lpPriceResp.result)
        this.loadingFlag1 = true
        // console.log('lp value:', this.lpPrice)
      }
    },
    // 获取lp的总供应量
    async getTotalSupply () {
      // console.log('*********TVL*************')
      const lpTotalSupply = await stakeLock.getLpTotalSupply(this.poolId || 0)
      if (lpTotalSupply.success) {
        this.loadingFlag2 = true
        this.lpTotalSupply = this.$gbUtils.formatBalance(lpTotalSupply.result)
      }
      // console.log('*********TVL*************')
    }
  },
  watch: {
    lpTotalSupply (val) {
      this.lpTotalPrice = (val * this.lpPrice).toFixed(2)
    },
    lpPrice (val) {
      this.lpTotalPrice = (val * this.lpTotalSupply).toFixed(2)
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      this.getLpPrice() // 获取当前池子lp的价值
      this.getTotalSupply() // 获取总质押流动性
      // console.log('this.lpTotalPrice', this.lpTotalPrice)
    }, 30000)
    this.getLpPrice() // 获取当前池子lp的价值
    this.getTotalSupply() // 获取总质押流动性
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
.total-tvl {
  text-align: center;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  .arrow {
    // padding-left: 100px;
    position: absolute;
    font-size: 24px;
    right: 10px;
    color: rgb(177, 174, 174);
  }
  .tvl-title {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #16172a;
    opacity: 1;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
      margin-right: 3px;
      margin-left: auto;
    }
    .tvl-title-name {
      margin-right: auto;
    }
  }
  .total-num {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    color: #16172a;
    opacity: 1;
    margin-top: 8px;
    // display: flex;
    // justify-content: center;
  }
}
</style>
