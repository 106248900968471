<template>
  <VanPopup
    v-model="show"
    style="width: 93%; max-width: 300px; height: 200px"
    round
    @click-overlay="closePop"
  >
    <div class="header">
      <div class="close-icon" @click="closePop">✕</div>
    </div>
    <div class="icon">
      <div>
        <i class="iconfont icon-chenggong arrow"></i>
      </div>
      <div class="notice">
        <slot class="notice">Success</slot>
      </div>
    </div>
  </VanPopup>
</template>

<script>
export default {
  name: 'SuccessPopup',
  data () {
    return {
      show: true
    }
  },
  props: {
    notice: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  methods: {
    // 弹窗显示及隐藏
    closePop () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
.icon {
  text-align: center;
  padding-top: 25px;
  i {
    color: #66eab9;
    font-size: 60px;
  }
  .notice {
    padding-top: 20px;
    font-size: 20px;
    color: #66eab9;
  }
}
</style>
