<template>
  <!-- 质押 -->
  <div class="page tab-page">
    <!-- 头部 3-18版本暫時隱藏入口 -->
    <template v-if="true">
      <TabList />
    </template>
    <!-- 总质押流动性 -->
    <TotalTvl />
    <div class="staking-wrap">
      <div class="dialog-wrap">
        <!-- 质押提示： 首次 -->
        <div class="wrap-title" @click="addLiquidity">
          <SvgIcon iconClass="MebUstd" class="medustd-icon" />
          <div>{{ staking.getLp }}</div>
          <i class="iconfont icon icon-view"></i>
        </div>
        <div class="staking-box">
          <div class="staking-content">
            <div class="staking-num-box">
              <div class="title">
                <div>{{ staking.amountOfStake }}</div>
                <div class="get-lp" v-if="!isLpBalance">
                  {{ stakeTips.getLp }}
                </div>
              </div>
            </div>
            <!-- 质押输入框 -->
            <div class="staking-input-box" :class="{ 'no-lp': !isLpBalance }">
              <input
                class="input-border"
                :placeholder="staking.addLiquidty.notice + needLp"
                v-model="stakingValue"
                @input="handleInputStakingValue"
                oninput="value=value.match(/^\d+(?:\.\d{0,8})?/)"
              />
              <div class="icon-box">
                <div
                  @click="handleToMax"
                  width="25px"
                  height="23px"
                  fontSize="15px"
                  class="max"
                >
                  {{ staking.max }}
                </div>
              </div>
            </div>
            <!-- 剩 -->
            <div class="balance-group">
              <div class="lp-balance">
                {{ staking.balance }}:&nbsp;<span v-if="loadingLp">{{
                  lpbalance || 0
                }}</span>
                <VanLoading v-else size="12" color="#000" />
                &nbsp;LP
              </div>
            </div>
            <div class="staking-num-title" style="font-size: 12px">
              {{ staking.chooseTime }}
            </div>
            <!-- 质押时间选择 -->
            <div class="menu">
              <div
                class="menu-item"
                v-for="(item, index) in timeOption"
                :key="item.id"
                :class="{ selectMenu: index === selectMenuIndex }"
                @click="handleMenuClick(item, index)"
              >
                <div class="day">{{ item.text }}</div>
                <div
                  class="x"
                  :class="{ 'x-select': index === selectMenuIndex }"
                >
                  {{ item.percent }}
                </div>
              </div>
              <!-- 质押活动提示 双倍算力-->
              <div class="active-tips" v-if="selectMenuIndex === 0 && false">
                *{{ staking.activeTip }}
              </div>
            </div>
            <div class="lp-usdt-box">
              <div class="lp-value-title">{{ staking.lp }}:</div>
              <div class="lp-value">{{ lpPrice || 0 }}USDT</div>
            </div>
            <div class="lp-usdt-box" v-if="false">
              <div class="lp-value-title">{{ staking.hashrate }}:</div>
              <div class="lp-value">{{ preViewLp }}MEB</div>
              <img src="../../../static/img/home/mineral_green.gif" alt="" />
            </div>
          </div>
          <!-- 按钮 -->
          <div class="botton-group">
            <!-- 授权 -->
            <!-- <div class="btn-wrap" v-if="btnLoading">
              <div class="staking-btn">
                <VanLoading color="#fff" />
              </div>
            </div> -->
            <!-- <template v-else>
              <div class="btn-wrap" v-if="!authLP">
                <div class="staking-btn" @click="approveContract" ref="authLP">
                  <span v-if="!authLPLoading">{{ stakeTips.Apporve }}</span>
                  <van-loading v-else color="#fff" />
                </div>
              </div> -->
              <!-- 质押 -->
              <!-- <div class="btn-wrap" v-else>
                <div
                  class="staking-btn"
                  fontSize="16px"
                  @click="hadnleLpStaking"
                >
                  <span v-if="!stakingLoading">{{ staking.stake }}</span>
                  <van-loading v-else color="#fff" />
                </div>
              </div> -->
            <!-- </template> -->
            <div class="btn-wrap">
              <div
                :class="{ 'redeem-btn': userStakeValue > 0 }"
                class="cancel-btn"
                width="58px"
                height="25px"
                fontSize="16px"
                @click="$gbUtils.handleToPage('/redeem')"
              >
                {{ staking.redeem }}
              </div>
            </div>
          </div>
          <!-- 质押提示 -->
          <div class="notice">{{ staking.notice }}</div>
        </div>
        <div class="wrap-title-bottom" :class="{ 'have-lp': lpbalance }">
          <div @click="removeLiquidity">{{ staking.remove }}</div>
          <i class="iconfont icon icon-view"></i>
        </div>
      </div>
    </div>
    <!-- 获取lp -->
    <AddLiquidity
      ref="addLiquidity"
      v-if="addShow"
      :userStakeValue="userStakeValue"
      @close="addLiquidity"
    ></AddLiquidity>
    <!-- 移除lp -->
    <Remove
      v-if="removeShow"
      ref="removeLiquidity"
      :lpObj="lpObj"
      :lpbalance="lpbalance"
      :allowanceLP="allowanceLP"
      :removeShow="removeShow"
      @close="removeLiquidity"
    ></Remove>
    <SuccessPopup @close="handleCloseSuccess" v-if="successShow">{{
      staking.success
    }}</SuccessPopup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pancekeFactory from '@/utils/factory' // factory
import USDTGEPABI from '@/static/abi/lpToken' // usdt-gep的abi文件
import stakeLock from '@/utils/stakeLock'
import {
  queryEstimateForceValue,
  queryStakeTime,
  queryInquiryStake,
  queryDeposit
} from '@/services/lpStake'
import config from '@/config/app.config'
import SvgIcon from '@/components/SvgIcon.vue'
import AddLiquidity from './components/AddLiquidity.vue'
import Remove from './components/Remove.vue'
import TotalTvl from '../components/TotalTvl.vue' // 总流动性质押
import SuccessPopup from '../../../components/common/SuccessPopup.vue'
import TabList from '../components/TabList.vue'
const contractsInfo = config.contractsInfo
export default {
  components: { SvgIcon, AddLiquidity, Remove, TotalTvl, SuccessPopup, TabList },
  data () {
    return {
      addShow: false,
      removeShow: false,
      successShow: false,
      tokenGep: contractsInfo.tokenGEPContract,
      tokenUsdt: contractsInfo.tokenUSDTContract,
      loadingLp: false, // lp余额loading
      lpbalance: null, // lp剩
      lpAddress: '', // lp的合约地址
      lpObj: {}, // lp对象
      lpPrice: 0, // lp价值
      preViewLp: 0, // 奖励原力值
      poolId: 0, // 质押池子ID
      timeId: 0, // 质押时间id
      stakingValue: null, // 质押剩
      allowanceLP: 0, // LP额度
      timeOption: [],
      isLpBalance: true,
      // selectMenuId: 0,
      selectMenuIndex: null,
      approveEnable: false,
      authLP: false, // LP授权
      authLPLoading: false, // 授权LP Loding
      stakingLoading: false, // 质押Loading
      btnLoading: true,
      userStakeValue: null, // 用户已经质押的u值
      needLp: '' // 用户需要质押的lp数量
    }
  },
  methods: {
    ...mapActions(['changeLoading']),
    showloading (message) {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 1)
      this.$emit('setLoadingMessage', message)
    },
    async handleInputStakingValue () {
      this.btnLoading = true
      await this.handleAllowanceLP()
    },
    addLiquidity () {
      this.addShow = !this.addShow
    },
    removeLiquidity () {
      this.removeShow = !this.removeShow
      // this.$refs.removeLiquidity.changeShow()
    },
    handleCloseSuccess () {
      this.successShow = !this.successShow
    },
    async init () {
      // this.getTimeList() // 获取质押时长
      this.getLp() // 获取lp信息+++剩
      // this.getPools() // 获取质押池数据
      this.getLpPrice() // 获取当前池子lp的价值
      this.getStakeBalance() // 获取当前质押剩
    },
    format_number (nStr) {
      var x, x1, x2
      nStr += ''
      x = nStr.split('.')
      x1 = x[0]
      x2 = x.length > 1 ? '.' + x[1] : ''
      var rgx = /(\d+)(\d{3})/
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2')
      }
      return x1 + x2
    },
    // 格式化 unit256转为十进制
    fromWei (val) {
      if (!val) return 0
      return this.$web3.fromWei(val)
    },
    // 获取预计原力值
    async getPreviewLp () {
      if (!this.stakingValue || !this.timeId) {
        this.preViewLp = 0
        return
      }
      // const rate = this.timeOption[this.selectMenuIndex].proportion
      // this.preViewLp = this.$gbUtils.formatBalance(this.stakingValue * this.lpPrice * rate)
      const resp = await queryEstimateForceValue({
        amount: this.stakingValue,
        timeId: this.timeId
      })
      if (!resp.success) return
      this.preViewLp = resp.result
    },
    // 获取对应lp数量对应的两个币的量
    getUsdtMebAmount (lpcount, index) {
      const params = this.$gbUtils.getUsdtMebAmount(
        lpcount,
        this.lpPrice,
        this.mebPrice
      )
      this.recordList[index].usdt = params.usdt
      this.recordList[index].meb = params.meb
    },
    // 获取lp相关信息
    async getLp () {
      // 显示loading
      this.loadingLp = false
      const resp = await pancekeFactory.getPair(
        this.tokenUsdt.address,
        this.tokenGep.address
      )
      if (!resp.success) {
        this.loadingLp = true
        return
      }
      const lpAddress = resp.result // lp的token合约地址
      console.log('lpaddress')
      this.lpAddress = lpAddress
      const lpObj = {
        abi: USDTGEPABI,
        address: lpAddress
      }
      this.lpObj = lpObj
      const lpbalanceResp = await this.$web3.balanceOf(lpObj)
      this.loadingLp = true // 隐藏loading
      if (!lpbalanceResp.success) return
      this.lpbalance = this.$gbUtils.formatBalance(lpbalanceResp.result)
      // console.log(this.lpbalance)
      this.handleAllowanceLP() // 获取lp的授权额度
    },
    // 获取质押时间列表
    async getTimeList () {
      const resp = await queryStakeTime()
      if (resp.success) {
        const timeOption = (resp.result || []).filter(
          (item) => item.poolId !== null && item.poolId !== ''
        )
        this.timeOption = (timeOption || []).map((item, index) => {
          item.text = `${item.name}${this.staking.day}`
          item.percent = parseFloat(item.proportion) * 100 + '%'
          // item.percent = parseInt(item.proportion) / 100 + 'x'
          return item
        })
      }
      if (!this.timeId && this.timeOption.length > 0) {
        this.handleMenuClick(this.timeOption[0], 0)
      }
    },
    // 时间选择
    handleMenuClick (item, index) {
      this.selectMenuIndex = index
      this.timeId = item.id
      this.poolId = item.poolId
      this.getLpPrice(item.poolId)
    },
    // max按钮
    handleToMax () {
      this.stakingValue = this.lpbalance
    },
    // 返回
    handleToBackPage () {
      this.$router.back(1)
    },
    // 获取lp授权给锁仓合约的额度
    async handleAllowanceLP () {
      const resp = await stakeLock.allowance(this.lpObj)
      this.btnLoading = false
      if (!resp.success) {
        this.authLP = false
        return
      }
      if (resp.success) {
        this.allowanceLP = this.$gbUtils.formatBalance(resp.result)
        console.log('this.allowanceLP', this.allowanceLP)
      }
      if (this.stakingValue > this.allowanceLP) {
        this.authLP = false
      } else {
        this.authLP = this.allowanceLP > 0
      }
    },
    // 授权
    async approveContract () {
      // if (this.stakingValue > parseInt(this.lpbalance) + 1) {
      //   return this.$toast(this.stakeTips.insufficient)
      // }
      if (!this.stakingValue) {
        return this.$toast('please input value')
      }
      // var message = this.Waiting + this.stakeTips.Apporve
      // this.$toast.loading({ duration: 0, message })
      // const stakingValue = this.stakingValue > 10000 ? this.stakingValue : 10000
      this.authLPLoading = true
      this.$refs.authLP.style.pointerEvents = 'none'
      if (this.stakingValue < this.allowanceLP) {
        this.$refs.authLP.style.pointerEvents = 'auto'
        this.authLPLoading = false
        this.authLP = true
        return
      }
      const resp = await stakeLock.approveContract(this.lpObj, 100000000000000) // 授权lp
      this.$toast.clear()
      this.$refs.authLP.style.pointerEvents = 'auto'
      if (!resp.success) {
        this.authLPLoading = false
        return this.$toast(resp.message.message)
      }

      // console.log('签名回调', resp)
      this.$refs.authLP.style.pointerEvents = 'auto'
      this.authLPLoading = false
      this.$toast('Success')
      this.approveEnable = false
      this.authLP = true
    },
    // 质押
    async hadnleLpStaking () {
      if (!this.stakingValue) {
        return this.$toast(this.stakeTips.inputNone)
      }
      if (this.stakingValue > parseInt(this.lpbalance) + 1) {
        return this.$toast(this.stakeTips.insufficient)
      }
      const needPrice = this.userStakeValue >= 1000 ? 100 : 1000
      const needLp = this.lpPrice
        ? parseInt(needPrice / this.lpPrice) + 1
        : 0
      if (this.stakingValue < needLp) {
        return this.$toast(
          this.$t('common.stakeTips.valueMin', { value: needLp })
        )
      }
      if (!this.timeId) {
        return this.$toast('please choose time')
      }
      this.stakingLoading = true
      var message = this.Waiting + this.stakeTips.Stake
      this.showloading(message)
      // const resp = await stakeLock.stakeLp(this.lpAddress, this.stakingValue, 7)
      const resp = await stakeLock.depositLp(this.poolId, this.stakingValue)
      // const resp = await stakeLock.getPools()
      console.log(resp)
      this.$emit('changeLoading', false)
      this.stakingLoading = false
      if (!resp.success) {
        return this.$toast('fail')
      }
      this.init() // 数据重新获取
      this.stakingValue = null // 清空输入框
      this.successShow = true
      // 链上数据区块会相差一块
      // const txHash = resp.result.transactionHash || ''
      // const startBlockNumber = resp.result.blockNumber || ''
      // this.pledgeLp(txHash, startBlockNumber) // 调用api进行质押
      // this.notifyApi() // 通知后端
    },

    // api质押
    async pledgeLp (txHash, startBlockNumber) {
      const params = {
        poolId: this.poolId,
        timeId: this.timeId,
        amount: this.stakingValue,
        txHash: txHash,
        startBlockNumber: startBlockNumber
      }
      const resp = await queryDeposit(params)
      if (!resp.success) return this.$toast(resp.message)
      else {
        return this.$toast(resp.message)
      }
    },
    // 通知后端
    async notifyApi () {
      const resp = await queryInquiryStake()
      console.log(resp)
    },
    // 获取质押池
    async getPools () {
      const resp = await stakeLock.getPools()
      // console.log('质押池', resp)
      this.poolList = resp.result
    },
    // 获取lp价值
    async getLpPrice () {
      const lpPriceResp = await stakeLock.getLpPrice(this.poolId || 0)
      if (lpPriceResp.success) {
        this.lpPrice = this.$gbUtils.formatBalance(lpPriceResp.result)
        console.log('lp value:')
      }
    },
    // 获取质押剩
    async getStakeBalance () {
      const resp = await stakeLock.getUserBalance()
      this.userStakeValue = resp.result || 0 // 用户已经质押的金额
      console.log('userbalance:', resp.result)
      const needPrice = this.userStakeValue >= 1000 ? 100 : 1000
      this.needLp = this.lpPrice
        ? parseInt(needPrice / this.lpPrice) + 1
        : 0
    }
  },
  computed: {
    ...mapState(['myAcount']),
    staking () {
      return this.$t('staking')
    },
    Waiting () {
      return this.$t('common.Waiting')
    },
    stakeTips () {
      return this.$t('common.stakeTips')
    },
    deposeTitle () {
      return this.$t('common.deposeTips.title', { needMeb: this.needMeb })
    },
    deposeTips () {
      return this.$t('common.deposeTips')
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    lpbalance (val) {
      val === 0 ? (this.isLpBalance = false) : (this.isLpBalance = true)
    },
    poolId (val) {
      if (val !== null) {
        this.getLpPrice(val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  // height: 1s0%;
  background: #f8f8f8;
  padding-top: 1px;
}
.medustd-icon {
  width: 30px;
  height: 30px;
}
.staking-wrap {
  background-size: 100% 100%;
  width: 95%;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;

  .tab {
    width: 88%;
    height: 40px;
    display: flex;
    background-color: #eaeef3;
    margin: 0 auto;
    line-height: 32px;
    border-radius: 10px;
    cursor: pointer;
    .tab-item {
      width: 48%;
      text-align: center;
      border-radius: 7px;
      height: 32px;
      margin-top: 4px;
      margin-left: 4px;
      font-size: 12px;
      color: #656e86;
    }
    .active {
      background-color: #fff;
      color: #16172a;
    }
  }

  .dialog-wrap {
    position: relative;
    background-size: 100% 100%;
    // width: 100%;
    box-sizing: border-box;
    margin: auto;
    background-size: 100% 100%;
    .wrap-title {
      width: 100%;
      display: flex;
      padding: 15px 20px;
      box-sizing: border-box;
      // justify-content: center;
      align-items: center;
      color: #25e2b1;
      div {
        font-size: 15px;
        font-weight: bold;
        color: #25e2b1;
        padding-left: 12px;
        cursor: pointer;
      }
      .icon {
        margin-left: 5px;
        width: 12px;
        height: 12px;
        cursor: pointer;
      }
    }
    .staking-box {
      background-color: #f9f9f9;
      padding: 20px 0;
      .staking-content {
        width: 80%;
        padding: 20px 20px 10px 20px;
        margin: 0 auto;
        border: 1px solid #e3e3e3;
        border-radius: 15px;
      }
    }
    .depose-tips {
      font-size: 12px;
      color: #a2a2a2;
    }
    .staking-num-box {
      display: flex;
      justify-content: space-between;
      align-content: center;
      // margin-bottom: 16px;
      .title {
        font-size: 12px;
        font-weight: bold;
        color: #16172a;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .get-lp {
          font-weight: 400;
          color: #ed8080;
        }
      }
      .balance-of {
        font-size: 13px;
        font-weight: 400;
      }
    }
    .balance-group {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      .lp-balance {
        display: flex;
        align-items: flex-end;
      }
      img {
        margin-left: 5px;
      }
    }
    .staking-input-box {
      display: flex;
      border-radius: 15px;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      margin-bottom: 10px;
      background-color: #f0f0f0;
      height: 50px;
      padding: 0 20px;
      .icon-box {
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: flex-end;
      }
      .input-border {
        background-color: #f0f0f0;
        background-size: 100% 100%;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        // padding: 10px;
        width: 60%;
        border: none;
        // padding-left: 5%;
        margin-right: 6px;
      }
      input::-webkit-input-placeholder {
        font-size: 12px;
        color: #818086;
      }
      img {
        width: 28px;
        height: 16px;
        margin-right: 3px;
      }

      .bitcoin {
        display: block;
        widows: 20px;
        height: 20px;
        margin-right: 6px;
        margin-left: 6px;
      }
      .diamond {
        display: block;
        widows: 20px;
        height: 20px;
        margin-right: 6px;
      }
      .lp {
        font-size: 14px;
        font-weight: 400;
        margin-left: 10px;
      }
      .max {
        width: 66px;
        height: 30px;
        background: #66eab9;
        opacity: 1;
        border-radius: 10px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        font-weight: bold;
        color: #2a2b33;
        cursor: pointer;
      }
    }

    .staking-num-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .lp-usdt-box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      margin-top: 20px;
      font-size: 12px;
      color: #a2a2a2;
      .lp-value-title {
        margin-right: 5px;
      }
      img {
        margin: 0 5px;
        width: 15px;
        height: 15px;
      }
    }
    .botton-group {
      padding: 15px 20px;
      display: flex;
      justify-content: space-evenly;
      .btn-wrap {
        width: 50%;
        text-align: center;
        font-size: 16px;

        .staking-btn {
          width: 90%;
          height: 50px;
          background-color: #66eab9;
          line-height: 50px;
          border-radius: 15px;
          color: #000;
          font-weight: bold;
          margin: 0 auto;
          cursor: pointer;
        }
        .cancel-btn:last-child {
          width: 90%;
          height: 50px;
          line-height: 50px;
          border-radius: 15px;
          color: #000;
          font-weight: bold;
          margin: 0 auto;
          cursor: pointer;
          border: 1px solid #e3e3e3;
          opacity: 1;
          background: #f9f9f9;
          color: #a2a2a2;
          border-radius: 15px;
        }
      }
    }
    .notice {
      padding: 0 30px;
      box-sizing: border-box;
      color: #a2a2a2;
      font-size: 10px;
      line-height: 18px;
      word-wrap: break-word;
      // word-break: break-all;
      white-space: pre-wrap;
    }
  }
  .wrap-title-bottom {
    width: 100%;
    display: flex;
    font-size: 15px;
    padding: 15px 20px 15px 30px;
    box-sizing: border-box;
    font-weight: bold;
    color: #c1c1c1;
    cursor: pointer;
    align-items: center;
    .icon {
      font-size: 12px;
      padding-left: 10px;
    }
  }
  .menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    .menu-item {
      position: relative;
      box-sizing: border-box;
      width: 30%;
      background-color: #f0f0f0;
      margin: 0 3px;
      margin-bottom: 10px;
      padding: 5px 0;
      overflow: hidden;
      cursor: pointer;
      border-radius: 10px;
      img {
        position: absolute;
        right: 1px;
        top: 1px;
        width: 6px;
        height: 4px;
      }
      .day {
        font-size: 15px;
        color: #16172a;
        font-weight: bold;
      }
      .x {
        font-size: 12px;
        color: #16172a;
      }
      .icon {
        position: absolute;
        right: -9px;
        top: -9px;
        width: 18px;
        height: 18px;
        background-color: #25e2b1;
        transform: rotate(45deg);
      }
    }
  }
  .unstaking-wrap {
    background-size: 100% 100%;
    width: 100%;
    min-height: 72vh;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 15px;
    padding: 20px;
    .paddng {
      padding: 30px;
      .lp-usdt-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
        .lp-value-title {
          font-size: 14px;
          font-weight: 500;
          margin-right: 20px;
        }
        .lp-value {
          font-size: 14px;
          font-weight: 400;
        }
      }
      .waring {
        font-size: 14px;
        font-weight: 500;
        color: #5f493d;
        margin-bottom: 20px;
        .alert {
          width: 20px;
          height: 20px;
        }
      }
      .btn-wrap {
        text-align: center;
        margin-top: 20px;
      }
    }
    .staking-record {
      font-size: 20px;
      font-weight: 500;
      color: #5f493d;
      margin-bottom: 10px;
      padding: 0 30px;
    }
    .staking-table {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 15px;
      width: 100%;
      margin: 0 auto;
      .table-title {
        flex: 1.2;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        border-bottom: 1px solid #f0f0f0;
        height: 25px;
      }
      .table-title:first-child {
        text-align: left;
        flex: 0.6;
      }
      .table-title:last-child {
        text-align: right;
        flex: 0.8;
      }
    }
    .value-list {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-size: 100% 100%;
      margin-bottom: 5px;
      .value-item {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1.2;
        text-align: center;
        font-size: 10px;
        // font-weight: 500;
        padding: 10px 0;
        // &.status {
        //   text-align: center;
        //   font-size: 9px;
        //   font-weight: 500;
        // }
        &.stakBlock {
          color: #40a9ff;
          cursor: pointer;
        }
      }
      .value-item:first-child {
        text-align: left;
        flex: 0.6;
      }
      .value-item:last-child {
        text-align: right;
        flex: 0.8;
      }
    }
    .unstaking-btn {
      // position: absolute;
      // right: 3%;
      // top: 50%;
      // transform: translateY(-50%);
      margin-left: auto;
      min-width: 40px;
      height: 24px;
      border-radius: 5px;
      background-color: #24c294;
      color: white;
      text-align: center;
      line-height: 24px;
      cursor: pointer;
      display: inline-block;
      font-size: 9px;
      padding: 0 5px;
    }
  }
  .reedom-wrap {
    background-size: 100% 100%;
    width: 100%;
    min-height: 450px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
  }
  .selectMenu {
    background-color: #66eab9 !important;
  }
}
.iframe {
  width: 105%;
  max-width: 532px;
  // margin: 0 auto;
  margin-left: -3%;
  margin-top: -35px;
  iframe {
    height: calc(100vh - 55px);
    border: none;
    width: 100%;
  }
}
.total-tvl {
  text-align: center;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  .tvl-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #16172a;
    opacity: 1;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
      margin-right: 3px;
      margin-left: auto;
    }
    .tvl-title-name {
      margin-right: auto;
    }
  }
  .total-num {
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    color: #16172a;
    opacity: 1;
    margin-top: 8px;
  }
}

.lp-icons {
  .svg-icon {
    width: 15px;
    height: 15px;
    z-index: 1;
    position: relative;
  }
  .usdt-icon {
    margin-left: -3px;
    z-index: 0;
  }
  margin: 0 5px;
}
.no-lp {
  border: 1px solid #ed8080;
}
.redeem-btn {
  color: #66eab9 !important;
  background-color: #fff !important;
  border: 1px solid #66eab9 !important;
}
.have-lp {
  color: #66eab9 !important;
  .icon {
    color: #66eab9 !important;
  }
}
.active-tips {
  font-size: 12px;
  color: red;
  // width: 30%;
  // text-align: center;
}
</style>
